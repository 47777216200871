//====================================
//              FOR LOOP             =
//====================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}
//--------  End of for loop  ---------//

//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}
//--------  End of for each loop  ---------//

///////////////
// variables //
///////////////
@values-array: auto, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17,
  20, 24, 32, 48, 64, 96, 160;
@media (min-width: 0) {
  .make-space(@prefix: e("-") );
}
@media (min-width: @screen-xs) {
  .make-space(@prefix: -xs-);
}
@media (min-width: @screen-sm) {
  .make-space(@prefix: -sm-);
}
@media (min-width: @screen-md) {
  .make-space(@prefix: -md-);
}
@media (min-width: @screen-lg) {
  .make-space(@prefix: -lg-);
}
@media (min-width: @screen-xl) {
  .make-space(@prefix: -xl-);
}
@media (min-width: @screen-xxl) {
  .make-space(@prefix: -xxl-);
}

// init functions
.make-space(@prefix) {
  .for(@values-array);
  .-each(@value) {
    .sp-p@{prefix}@{value} {
      padding: ~"@{value}px" !important;
    }
    .sp-py@{prefix}@{value} {
      padding-top: ~"@{value}px" !important;
      padding-bottom: ~"@{value}px" !important;
    }
    .sp-px@{prefix}@{value} {
      padding-left: ~"@{value}px" !important;
      padding-right: ~"@{value}px" !important;
    }
    .sp-pt@{prefix}@{value} {
      padding-top: ~"@{value}px" !important;
    }
    .sp-pl@{prefix}@{value} {
      padding-left: ~"@{value}px" !important;
    }
    .sp-pr@{prefix}@{value} {
      padding-right: ~"@{value}px" !important;
    }
    .sp-pis@{prefix}@{value} {
      padding-inline-start: ~"@{value}px" !important;
    }
    .sp-pie@{prefix}@{value} {
      padding-inline-end: ~"@{value}px" !important;
    }
    .sp-pb@{prefix}@{value} {
      padding-bottom: ~"@{value}px" !important;
    }
    .sp-m@{prefix}@{value} {
      margin: ~"@{value}px" !important;
    }
    .sp-my@{prefix}@{value} {
      margin-top: ~"@{value}px" !important;
      margin-bottom: ~"@{value}px" !important;
    }
    .sp-mx@{prefix}@{value} {
      margin-left: ~"@{value}px" !important;
      margin-right: ~"@{value}px" !important;
    }
    .sp-mt@{prefix}@{value} {
      margin-top: ~"@{value}px" !important;
    }
    .sp-ml@{prefix}@{value} {
      margin-left: ~"@{value}px" !important;
    }
    .sp-mr@{prefix}@{value} {
      margin-right: ~"@{value}px" !important;
    }
    .sp-mb@{prefix}@{value} {
      margin-bottom: ~"@{value}px" !important;
    }
    .sp-mis@{prefix}@{value} {
      margin-inline-start: ~"@{value}px" !important;
    }
    .sp-mie@{prefix}@{value} {
      margin-inline-end: ~"@{value}px" !important;
    }
  }
}

.sp-ml-auto {
  margin-left: auto;
}

.sp-mr-auto {
  margin-right: auto;
}

.sp-mt-auto {
  margin-top: auto;
}
.sp-mb-auto {
  margin-bottom: auto;
}
.sp-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.sp-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
